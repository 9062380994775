import React from 'react';
import classnames from 'classnames';

import Accent from '../Accent';
import { HTMLContent } from '../Content';

import styles from './styles.module.less';
import heroes from '../shared/heroes-all.module.less';

const defaultPhoto = `whiteMosaic`;

const AboutBEModule = ({ html, image, backgroundImage, backgroundImageCustom, darkText }) => {
   const aboutBEClass = darkText ? `${styles.aboutBE} ${styles.aboutBEDark}` : styles.aboutBE;
   let heroBackground = !!backgroundImage ? backgroundImage : defaultPhoto;
   let cls = classnames(styles.widget, {
      [heroes[heroBackground]]: !backgroundImageCustom
   });
   let style = {};
   if (!!backgroundImageCustom) {
      style.backgroundImage = `url("${backgroundImageCustom}")`;
   }

   return (
      <section className={cls} style={style}>
         <div className={styles.inner}>
            <div id="aboutBE" className={aboutBEClass}>
               <div className={styles.row}>
                  {image && (
                     <div className={styles.imageContainer}>
                        <img src={image} className={styles.image} alt="" />
                     </div>
                  )}
                  <div className={styles.textContainer}>
                     <h1 id="about-bay-equity" className={styles.sectionTitle}>
                        About Bay Equity
                     </h1>
                     <Accent align="center" />
                     <HTMLContent content={html} />
                  </div>
               </div>
            </div>
         </div>
      </section>
   );
};

export default AboutBEModule;
