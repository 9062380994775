import React, { useState, UIEvent } from 'react';
import GoogleMapReact from 'google-map-react';
import { googleMapApiKey } from '../../lib/env';
import { defaultMapBranch } from '../../lib/brand';
import Popup from './Popup';
import Marker from './Marker';

interface Branch {
   title: string;
   address1: string;
   address2: string;
   address3: string;
   city: string;
   state: string;
   zip: string;
   lat: number;
   lon: number;
}

interface MapProps {
   branch: Branch;
   isPreview: boolean;
}

const Map = (props: MapProps) => {
   const { branch = defaultMapBranch, isPreview } = props;
   const [popupClicked, setPopupClicked] = useState(true);

   if (!branch) return null;

   const disable = (e: UIEvent) => {
      e.stopPropagation();
      e.preventDefault();
   };

   const handleInteraction = (e: UIEvent) => {
      return isPreview ? disable(e) : true;
   };

   const handleClick = () => {
      setPopupClicked(!popupClicked);
   };

   return (
      // Important! Always set the container height explicitly
      <section
         onClick={handleInteraction}
         onScroll={handleInteraction}
         style={{ height: '345px', width: '100%', marginTop: '60px' }}>
         <GoogleMapReact
            bootstrapURLKeys={{ key: googleMapApiKey }}
            defaultCenter={{ lat: branch.lat, lng: branch.lon }}
            center={{ lat: branch.lat, lng: branch.lon }}
            defaultZoom={11}
            options={
               isPreview
                  ? {
                       panControl: false,
                       gestureHandling: 'none',
                       draggable: false,
                       zoomControl: false,
                       scrollwheel: false,
                       disableDoubleClickZoom: false,
                       fullscreenControl: false
                    }
                  : {}
            }>
            {popupClicked ? (
               <Popup
                  title={branch.title}
                  address={[branch.address1, branch.address2, branch.address3, branch.city, branch.state, branch.zip]}
                  handleClick={handleClick}
               />
            ) : (
               <Marker lat={branch.lat} lng={branch.lon} title={branch.title} handleClick={handleClick} />
            )}
         </GoogleMapReact>
      </section>
   );
};

export default Map;
