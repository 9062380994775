import React from 'react';
import { imgBrand, isBe } from '../../../lib/brand';
import styles from './styles.module.less';

var beBug = imgBrand(isBe ? 'be-circle-mark.png' : 'rf-circle-mark.png');

interface MarkerProps {
   lat: number; // lat/lng aren't used, but are required for map to work (google must be sniffing dom?)
   lng: number;
   title: string;
   handleClick: () => void;
}

const Marker = (props: MarkerProps) => {
   const { title, handleClick } = props;
   return (
      <div onClick={handleClick} className={styles.container}>
         <img src={beBug} alt={title} className={styles.bebug} />
      </div>
   );
};

export default Marker;
