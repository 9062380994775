/* eslint-disable camelcase */
import { random } from 'lodash';
import { imgBrand } from '../../lib/brand';

var check_b1 = imgBrand('check_b1.png', 'FileIcon');
var check_b2 = imgBrand('check_b2.png', 'FileIcon');
var check_o1 = imgBrand('check_o1.png', 'FileIcon');
var check_o2 = imgBrand('check_o2.png', 'FileIcon');
var check_o3 = imgBrand('check_o3.png', 'FileIcon');
var gif_b1 = imgBrand('gif_b1.png', 'FileIcon');
var gif_b2 = imgBrand('gif_b2.png', 'FileIcon');
var gif_o1 = imgBrand('gif_o1.png', 'FileIcon');
var gif_o2 = imgBrand('gif_o2.png', 'FileIcon');
var gif_o3 = imgBrand('gif_o3.png', 'FileIcon');
var jpg_b1 = imgBrand('jpg_b1.png', 'FileIcon');
var jpg_b2 = imgBrand('jpg_b2.png', 'FileIcon');
var jpg_o1 = imgBrand('jpg_o1.png', 'FileIcon');
var jpg_o2 = imgBrand('jpg_o2.png', 'FileIcon');
var jpg_o3 = imgBrand('jpg_o3.png', 'FileIcon');
var pdf_b1 = imgBrand('pdf_b1.png', 'FileIcon');
var pdf_b2 = imgBrand('pdf_b2.png', 'FileIcon');
var pdf_o1 = imgBrand('pdf_o1.png', 'FileIcon');
var pdf_o2 = imgBrand('pdf_o2.png', 'FileIcon');
var pdf_o3 = imgBrand('pdf_o3.png', 'FileIcon');
var pic_b1 = imgBrand('pic_b1.png', 'FileIcon');
var pic_b2 = imgBrand('pic_b2.png', 'FileIcon');
var pic_o1 = imgBrand('pic_o1.png', 'FileIcon');
var pic_o2 = imgBrand('pic_o2.png', 'FileIcon');
var pic_o3 = imgBrand('pic_o3.png', 'FileIcon');
var png_b1 = imgBrand('png_b1.png', 'FileIcon');
var png_b2 = imgBrand('png_b2.png', 'FileIcon');
var png_o1 = imgBrand('png_o1.png', 'FileIcon');
var png_o2 = imgBrand('png_o2.png', 'FileIcon');
var png_o3 = imgBrand('png_o3.png', 'FileIcon');
var url_o3 = imgBrand('url_o3.png', 'FileIcon');
var url_o1 = imgBrand('url_o1.png', 'FileIcon');
var url_o2 = imgBrand('url_o2.png', 'FileIcon');
var url_b1 = imgBrand('url_b1.png', 'FileIcon');
var url_b2 = imgBrand('url_b2.png', 'FileIcon');
var zip_b1 = imgBrand('zip_b1.png', 'FileIcon');
var zip_b2 = imgBrand('zip_b2.png', 'FileIcon');
var zip_o1 = imgBrand('zip_o1.png', 'FileIcon');
var zip_o2 = imgBrand('zip_o2.png', 'FileIcon');
var zip_o3 = imgBrand('zip_o3.png', 'FileIcon');

var ppt = imgBrand('ppt.png', 'FileIcon');
var word = imgBrand('word.png', 'FileIcon');
var xls = imgBrand('xlsx.png', 'FileIcon');

const images = {
   check_b2,
   check_b1,
   check_o1,
   check_o2,
   check_o3,
   gif_b1,
   gif_b2,
   gif_o1,
   gif_o2,
   gif_o3,
   jpg_b1,
   jpg_b2,
   jpg_o1,
   jpg_o2,
   jpg_o3,
   pdf_b1,
   pdf_b2,
   pdf_o1,
   pdf_o2,
   pdf_o3,
   pic_b1,
   pic_b2,
   pic_o1,
   pic_o2,
   pic_o3,
   png_b1,
   png_b2,
   png_o1,
   png_o2,
   png_o3,
   url_o3,
   url_o1,
   url_o2,
   url_b1,
   url_b2,
   zip_b1,
   zip_b2,
   zip_o1,
   zip_o2,
   zip_o3,
   word,
   ppt,
   xls
};

const backgrounds = ['b1', 'b2', 'o1', 'o2', 'o3'];

const doc = ['doc', 'dot', 'wbk', 'docx', 'docm', 'dotx', 'dotm', 'docb', 'doc', 'dot', 'wbk'];

const Excel = ['xls', 'xlt', 'xlm', 'xlsx', 'xlsm', 'xltx', 'xltm', 'xlsb', 'xla', 'xlam', 'xll', 'xlw'];

const powerPoint = ['pptx', 'pptm', 'potx', 'potm', 'ppam', 'ppsx', 'ppsm', 'sldx', 'sldm', 'ppt', 'pot', 'pps'];

const exact = ['gif', 'jpg', 'pdf', 'png', 'url', 'zip'];

const pics = ['tif', 'tiff', 'psd', 'jpeg', 'ai', 'sketch'];

let lastBg = backgrounds[0];

const getBg = () => {
   let r = random(backgrounds.length - 1);
   let bg = backgrounds[r];
   if (bg === lastBg) {
      return getBg();
   }
   lastBg = bg;
   return bg;
};

/**
 *
 * @param {String} extension
 */
const getImage = extension => {
   let bg = getBg();
   let ext = extension.toLowerCase();
   if (doc.includes(ext)) return images.doc;
   if (Excel.includes(ext)) return images.xls;
   if (powerPoint.includes(ppt)) return images.ppt;
   if (exact.includes(ext)) {
      let key = `${ext}_${bg}`;
      return images[key];
   }
   if (pics.includes(ext)) {
      let key = `pic_${bg}`;
      return images[key];
   }
   // fallback to a checklist
   let key = `check_${bg}`;
   return images[key];
};

export default getImage;
