import React from 'react';

import styles from './styles.module.less';
import { brandLogoUuid } from '../../../lib/brand';

const Slide = ({ photo, alt }) => {
   if (photo.includes(brandLogoUuid)) {
      // show logo
      return (
         <div className={styles.LogoContainer}>
            <picture className={styles.Logo}>
               <img data-blink-src={photo} alt="" />
            </picture>
         </div>
      );
   } else {
      // show portrait
      return (
         <picture className={styles.Portrait}>
            <img data-blink-src={photo} alt="" />
         </picture>
      );
   }
};

export default Slide;
