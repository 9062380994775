import React from 'react';
import classnames from 'classnames';

import Accent from '../Accent';

import { teamLoanOfficerPhoto } from '../../lib/gtm';
import { brandLogo200 } from '../../lib/brand';

import styles from './styles.module.less';
import heroes from '../shared/heroes-common.module.less';

const fallback = brandLogo200;
const defaultBackgroundImage = 'teamMembers';

const TeamMembers = ({
   allowLink = true,
   managers = [],
   loanOfficers = [],
   staff = [],
   backgroundImage,
   backgroundImageCustom = '',
   photoAlignment = 'center',
   compact,
   templateKey
}) => {
   const shouldDisplay =
      (!!managers && managers.length > 0) ||
      (!!loanOfficers && loanOfficers.length > 0) ||
      (!!staff && staff.length > 0);

   if (!shouldDisplay) {
      return null;
   }

   let bg = backgroundImage ?? defaultBackgroundImage;
   let cls = classnames(styles.TeamMembers, {
      [heroes[bg]]: !backgroundImageCustom,
      [styles.compact]: compact
   });
   let style = {
      backgroundPosition: photoAlignment
   };
   if (!!backgroundImageCustom) {
      style.backgroundImage = `url("${backgroundImageCustom}")`;
   }

   const title = templateKey === 'loan-officer' ? 'My amazing team' : 'Our amazing team';

   return (
      <section className={cls} style={style} data-background="photo">
         <div className={styles.panel}>
            <h1>{title}</h1>
            <Accent align="left" />
            <div className={styles.members}>
               {managers && managers.map((s, i) => <Staffer key={i} {...s} />)}
               {loanOfficers.map((lo, i) => (
                  <LO {...lo} allowLink={allowLink} key={i} />
               ))}
               {staff && staff.map((s, i) => <Staffer key={i} {...s} />)}
            </div>
         </div>
      </section>
   );
};

export default TeamMembers;

const PhotoFallback = ({ photo, name }) => {
   let pic = photo || fallback;
   return (
      <div className={styles.badge} data-gtm={teamLoanOfficerPhoto}>
         <img data-blink-src={pic} alt={name} data-gtm={teamLoanOfficerPhoto} />
      </div>
   );
};

const LinkFallback = ({ allowLink, slug, children }) => {
   if (allowLink) {
      return (
         <a href={slug} data-gtm={teamLoanOfficerPhoto}>
            {children}
         </a>
      );
   }
   return <>{children}</>;
};

const LO = ({ slug, photo, name, email, jobTitle, licenseIds = [], allowLink }) => {
   let lids = licenseIds || [];
   return (
      <div className={styles.member}>
         <LinkFallback allowLink={allowLink} slug={slug}>
            <PhotoFallback photo={photo} name={name} />
            <h2 className={styles.name} data-gtm={teamLoanOfficerPhoto}>
               {name}
            </h2>
            <h3 className={styles.jobTitle} data-gtm={teamLoanOfficerPhoto}>
               {jobTitle}
            </h3>
            <h4 className={styles.license} data-gtm={teamLoanOfficerPhoto}>
               {lids.map((lid, i) => {
                  const { license, id } = lid;
                  return (
                     <span key={i} data-gtm={teamLoanOfficerPhoto}>
                        {license}#{id}
                     </span>
                  );
               })}
            </h4>
         </LinkFallback>
         <a href={`mailto:${email}`} className={styles.email} data-gtm={teamLoanOfficerPhoto}>
            {email}
         </a>
      </div>
   );
};

const Staffer = ({ photo, name, email, title, license }) => {
   return (
      <div className={styles.member}>
         <PhotoFallback photo={photo} name={name} />
         <span className={styles.name}>{name}</span>
         <span className={styles.jobTitle}>{title}</span>
         <span className={styles.jobTitle}>{license}</span>
         <a href={`mailto:${email}`} className={styles.email} data-gtm={teamLoanOfficerPhoto}>
            {email}
         </a>
      </div>
   );
};
