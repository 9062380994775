import React, { Component } from 'react';
import { img } from '../../lib/brand';
import Accent from '../Accent';
import styles from './styles.module.less';

var logo = img('logo.svg', 'InstagramFeed');

export default class InstagramFeed extends Component {
   state = {
      ok: false,
      feed: []
   };

   componentDidMount() {
      const { instagramToken } = this.props;
      if (!instagramToken) return;
      this.fetchMedia(instagramToken);
   }

   componentDidUpdate(prevProps) {
      const { instagramToken } = this.props;
      if (instagramToken !== prevProps.instagramToken) {
         this.fetchMedia(instagramToken);
      }
   }

   fetchMedia(token) {
      const url = `https://api.instagram.com/v1/users/self/media/recent/?access_token=${token}&count=8`;
      fetch(url)
         .then(response => {
            if (response.ok) {
               this.setState({ ok: true });
               return response.json();
            } else {
               throw new Error('API request failed');
            }
         })
         .then(json => {
            let feed = json.data.map(i => {
               let {
                  id,
                  link,
                  images: {
                     thumbnail: { url: image }
                  },
                  caption
               } = i;
               return {
                  id,
                  link,
                  image,
                  text: (caption && caption.text) || ''
               };
            });
            this.setState({ feed });
         })
         .catch(err => {
            this.setState({ ok: false });
         });
   }

   renderFeedImage = ({ id, link, image, text }) => {
      return (
         <li key={id}>
            <a href={link}>
               <img data-blink-src={image} width="150" height="150" alt={text} />
            </a>
         </li>
      );
   };

   render() {
      const { instagramToken } = this.props;
      const { ok, feed } = this.state;
      if (!instagramToken || !ok) return null;
      return (
         <section className={styles.Instagram} data-background="dark">
            <div className={styles.inner}>
               <div className={styles.container}>
                  <h1 className={styles.title}>
                     See what I’m up to
                     <br />
                     on Instagram
                  </h1>
                  <Accent align="left" />
                  <img src={logo} className={styles.instalogo} alt="" />
                  <ul className={styles.feed}>{feed.map(i => this.renderFeedImage(i))}</ul>
               </div>
            </div>
         </section>
      );
   }
}
