import React from 'react';
import { isLocal } from '../../lib/env';
import Video from '../Video';
import Twitter from '../Twitter';
import Map from '../Map';
import TestimonialsWidget from '../TestimonialsWidget';
import Calendly from '../Calendly';
import Bookings from '../Bookings';
import BlogRoll from '../BlogRoll';
import FutureEvents from '../FutureEvents';
import Awards from '../Awards';
import Calcs from '../Calculators';
import InstagramFeed from '../InstagramFeed';
import ResourceModule from '../ResourceModule';
import FacebookPage from '../Facebook';
import HomebotModule from '../HomebotModule';
import TeamMembers from '../TeamMembers';
import Profile from '../Profile';
import ContentModule from '../ContentModule';
import ContactUsModule from '../ContactUsModule';
import G2HCalculatorModule from '../G2HCalculatorModule';
import AboutG2HModule from '../AboutG2HModule';
import AboutCareModule from '../AboutCareModule';
import AboutBEModule from '../AboutBEModule';
import CustomTestimonialsWidget from '../CustomTestimonialsWidget';
import CareTestimonialsWidget from '../CareTestimonialsWidget';
import BecomePartnerWidget from '../BecomePartnerWidget';

const ModuleList = ({
   firestoreId,
   modules,
   applyNow,
   applyNowScript,
   twitter,
   branch,
   isPreview = false,
   blogs,
   corporateBlogs,
   review,
   isReviewInit,
   hasReviewErrored,
   shouldDisplaySurvey,
   stars,
   ratingsCount,
   blogWord,
   loanOfficers = [],
   managers = [],
   staff = [],
   teamMembersAllowLOLink = true,
   templateKey = 'loan-officer',
   pageType = '',
   slug,
   ownerSlug,
   socialIcons = [],
   gallery = [],
   licenseIds = [],
   name,
   jobTitle,
   contactInfo = [],
   profileHtml,
   logIn,
   staticTextItems,
   graphic,
   profile,
   pageTitle,
   pageUrl,
   leadEmailInfo,
   branches = [],
   realtor,
   searchVisibility,
   overrideAddress,
   overrideAddressText,
   licenseLink
}) => {
   // module renderers
   const renderTwitter = twitter => <Twitter key="twitter" url={twitter} />;
   const renderFacebookPage = facebookPageUrl => <FacebookPage key="facebookPage" facebookPageUrl={facebookPageUrl} />;
   const renderInstagram = instagramToken => <InstagramFeed key="instagram" instagramToken={instagramToken} />;
   const renderVideo = (videos = [], backgroundImageCustom, darkTitleText) => (
      <Video key="video" videos={videos} backgroundImageCustom={backgroundImageCustom} darkTitleText={darkTitleText} />
   );
   const renderMap = (branch, isPreview) => <Map key="map" branch={branch} isPreview={isPreview} />;
   const renderCalc = (
      showCalculatorMortgage,
      showCalculatorRentVsBuy,
      showCalculatorHomeAffordability,
      showCalculatorRequiredIncome,
      showCalculatorRefinance
   ) => (
      <Calcs
         key="calculators"
         audience={templateKey}
         show
         showCalculatorMortgage={showCalculatorMortgage}
         showCalculatorRentVsBuy={showCalculatorRentVsBuy}
         showCalculatorHomeAffordability={showCalculatorHomeAffordability}
         showCalculatorRequiredIncome={showCalculatorRequiredIncome}
         showCalculatorRefinance={showCalculatorRefinance}
         isPreview={isPreview}
         staticTextItems={staticTextItems}
      />
   );
   const renderCalendar = (calendar, backgroundImage, darkTitleText) => (
      <Calendly key="calendar" calendar={calendar} backgroundImage={backgroundImage} darkTitleText={darkTitleText} />
   );
   const renderBookings = (name, bookings, backgroundImage, darkTitleText) => (
      <Bookings
         key="bookings"
         name={name}
         bookings={bookings}
         backgroundImage={backgroundImage}
         darkTitleText={darkTitleText}
      />
   );
   const renderBlog = (blogs, corporateBlogs, blogWord, slug) => (
      <BlogRoll key="blog" blogs={blogs} corporateBlogs={corporateBlogs} showBlogs={true} word={blogWord} slug={slug} />
   );
   const renderEvents = (event1, event2, event3) => {
      return <FutureEvents key="events" event1={event1} event2={event2} event3={event3} />;
   };
   const renderResources = (resourceIntro, resourceList) => (
      <ResourceModule key="resources" intro={resourceIntro || undefined} resources={resourceList} />
   );
   const renderAwards = awards => <Awards key="awards" awards={awards} />;
   const renderTestimonials = (reviews, isReviewInit, hasReviewErrored) => {
      const show = (isReviewInit && !hasReviewErrored) || isLocal;

      if (isLocal && (!reviews || reviews.length == 0)) {
         reviews = [
            {
               firstname: 'First',
               city: 'City',
               state: 'ST',
               description: 'Description',
               rating: '4.5',
               reply: { reply: 'Reply', name: 'Name' }
            }
         ];
      }
      if (show) {
         return (
            <TestimonialsWidget
               key="testimonials"
               items={reviews.map((s, i) => ({
                  key: i,
                  name: s.firstname,
                  city: s.city,
                  state: s.state,
                  text: s.description,
                  rating: s.rating,
                  reply: s.reply
               }))}
            />
         );
      }
      return null;
   };
   const renderHomebot = id => <HomebotModule key="homebot" id={id} />;
   const renderTeamMembers = (
      loanOfficers,
      managers,
      staff,
      teamMembersAllowLOLink,
      backgroundImage,
      backgroundImageCustom
   ) => (
      <TeamMembers
         key="teamMembers"
         managers={managers}
         loanOfficers={loanOfficers}
         staff={staff}
         allowLink={teamMembersAllowLOLink}
         backgroundImage={backgroundImage}
         backgroundImageCustom={backgroundImageCustom}
         templateKey={templateKey}
      />
   );
   const renderProfile = (
      templateKey,
      branch,
      profileShowCompactView,
      profileTitle,
      profileBody,
      useBackgroundImage,
      backgroundImage,
      backgroundImageCustom,
      darkTitleText,
      searchVisibility,
      overrideAddress,
      overrideAddressText,
      licensedInText,
      licenseLink
   ) => {
      const { address1, address2, address3, city, state, zip, email, phone, mobilePhone, fax } = contactInfo || '';
      const slugValue = ownerSlug || slug;

      return (
         <Profile
            key="profile"
            templateKey={templateKey}
            showCompactView={profileShowCompactView}
            applyNow={applyNow}
            applyNowScript={applyNowScript}
            logIn={logIn}
            slug={slugValue}
            branch={branch}
            contactInfo={contactInfo}
            address1={address1}
            address2={address2}
            address3={address3}
            city={city}
            state={state}
            zip={zip}
            socialIcons={socialIcons}
            gallery={gallery}
            licenseIds={licenseIds}
            name={name}
            jobTitle={jobTitle}
            email={email}
            phone={phone}
            mobilePhone={mobilePhone}
            fax={fax}
            html={profileHtml}
            graphic={graphic}
            isReviewInit={isReviewInit}
            hasReviewErrored={hasReviewErrored}
            shouldDisplaySurvey={shouldDisplaySurvey}
            stars={stars}
            modules={modules}
            ratingsCount={ratingsCount}
            title={profileTitle}
            body={profileBody}
            realtor={realtor}
            backgroundImage={useBackgroundImage ? backgroundImage : ''}
            backgroundImageCustom={useBackgroundImage ? backgroundImageCustom : ''}
            darkText={useBackgroundImage ? darkTitleText : true}
            searchVisibility={searchVisibility}
            overrideAddress={overrideAddress}
            overrideAddressText={overrideAddressText}
            licensedInText={licensedInText}
            licenseLink={licenseLink}
         />
      );
   };
   const renderContent = (image, title, html) => <ContentModule key="content" img={image} title={title} html={html} />;
   const renderContactUs = (
      firestoreId,
      isPreview,
      moduleIndex,
      contactFormFields,
      contactFormTitle,
      darkTitleText,
      contactUsText,
      backgroundImage,
      backgroundImageCustom,
      contactUsTitle,
      contactUsBammUrl,
      contactUsConfirmEmailFrom,
      contactUsConfirmEmailText,
      contactUsLeadEmailCcList,
      branch
   ) => {
      const slugValue = ownerSlug || slug;

      return (
         <ContactUsModule
            firestoreId={firestoreId}
            key="contactUs"
            isPreview={isPreview}
            moduleIndex={moduleIndex}
            fields={contactFormFields}
            formTitle={contactFormTitle}
            darkText={darkTitleText}
            html={contactUsText}
            img={backgroundImage}
            customPhoto={backgroundImageCustom}
            title={contactUsTitle}
            modules={modules}
            bammUrl={contactUsBammUrl}
            confirmEmailFrom={contactUsConfirmEmailFrom}
            confirmEmailText={contactUsConfirmEmailText}
            leadEmailCcList={contactUsLeadEmailCcList}
            gallery={gallery}
            contact={contactInfo}
            branch={branch}
            profile={profile}
            ownerSlug={slugValue}
            pageTitle={pageTitle}
            pageUrl={pageUrl}
            templateKey={templateKey}
            staticTextItems={staticTextItems}
         />
      );
   };
   const renderG2HCalculator = (
      firestoreId,
      isPreview,
      moduleIndex,
      g2hCalculatorFormFields,
      g2hCalculatorFormTitle,
      darkTitleText,
      g2hCalculatorText,
      backgroundImage,
      backgroundImageCustom,
      g2hCalculatorTitle,
      g2hCalculatorBammUrl,
      lenderCreditPercentage,
      realtorCommissionPercentage,
      realtorCreditPercentage,
      branch,
      leadEmailInfo
   ) => {
      const slugValue = ownerSlug || slug;

      return (
         <G2HCalculatorModule
            firestoreId={firestoreId}
            key="g2hCalculator"
            isPreview={isPreview}
            moduleIndex={moduleIndex}
            fields={g2hCalculatorFormFields}
            formTitle={g2hCalculatorFormTitle}
            darkText={darkTitleText}
            html={g2hCalculatorText}
            img={backgroundImage}
            customPhoto={backgroundImageCustom}
            title={g2hCalculatorTitle}
            modules={modules}
            bammUrl={g2hCalculatorBammUrl}
            gallery={gallery}
            contact={contactInfo}
            branch={branch}
            lenderCreditPercentage={lenderCreditPercentage}
            realtorCommissionPercentage={realtorCommissionPercentage}
            realtorCreditPercentage={realtorCreditPercentage}
            profile={profile}
            ownerSlug={slugValue}
            pageTitle={pageTitle}
            pageUrl={pageUrl}
            templateKey={templateKey}
            leadEmailInfo={leadEmailInfo}
            branches={branches}
         />
      );
   };
   const renderAboutG2H = (aboutG2HHtml, aboutG2HCobrandUrl, aboutG2HIndustryIcons) => {
      return (
         <AboutG2HModule
            key="aboutG2H"
            html={aboutG2HHtml}
            cobrandUrl={aboutG2HCobrandUrl}
            icons={aboutG2HIndustryIcons}
         />
      );
   };
   const renderAboutBE = (aboutBEHtml, aboutBEImage, backgroundImage, backgroundImageCustom, darkTitleText) => {
      return (
         <AboutBEModule
            key="aboutBE"
            html={aboutBEHtml}
            image={aboutBEImage}
            backgroundImage={backgroundImage}
            backgroundImageCustom={backgroundImageCustom}
            darkText={darkTitleText}
         />
      );
   };
   const renderAboutCare = (
      firestoreId,
      isPreview,
      moduleIndex,
      aboutCareHtml,
      aboutCareImage,
      backgroundImage,
      backgroundImageCustom,
      allowDiscountedRealtorFee,
      noAppraisalFeeCredit,
      bammUrl,
      contactFormFields,
      contactFormTitle,
      contactFormSubtitle,
      darkTitleText,
      lenderCreditPercentage,
      realtorCreditPercentage,
      realtorCommissionPercentage,
      pageType
   ) => {
      return (
         <AboutCareModule
            firestoreId={firestoreId}
            key="aboutCare"
            isPreview={isPreview}
            moduleIndex={moduleIndex}
            html={aboutCareHtml}
            image={aboutCareImage}
            allowDiscountedRealtorFee={allowDiscountedRealtorFee}
            noAppraisalFeeCredit={noAppraisalFeeCredit}
            backgroundImage={backgroundImage}
            backgroundImageCustom={backgroundImageCustom}
            bammUrl={bammUrl}
            fields={contactFormFields}
            contactFormTitle={contactFormTitle}
            contactFormSubtitle={contactFormSubtitle}
            darkText={darkTitleText}
            lenderCreditPercentage={lenderCreditPercentage}
            realtorCreditPercentage={realtorCreditPercentage}
            realtorCommissionPercentage={realtorCommissionPercentage}
            pageTitle={pageTitle}
            slug={slug}
            leadEmailInfo={leadEmailInfo}
            pageType={pageType}
         />
      );
   };
   const renderCustomTestimonials = (customTestimonials, backgroundImage, backgroundImageCustom) => {
      return (
         <CustomTestimonialsWidget
            key="customTestimonials"
            items={customTestimonials}
            backgroundImage={backgroundImage}
            backgroundImageCustom={backgroundImageCustom}
         />
      );
   };
   const renderCareTestimonials = careTestimonials => {
      return <CareTestimonialsWidget key="careTestimonials" items={careTestimonials} />;
   };
   const renderBecomePartner = (backgroundImage, backgroundImageCustom) => {
      return (
         <BecomePartnerWidget
            key="becomePartner"
            backgroundImage={backgroundImage}
            backgroundImageCustom={backgroundImageCustom}
         />
      );
   };

   const renderModule = (firestoreId, module, moduleIndex) => {
      const { type, display } = module;
      const {
         contactFormFields,
         backgroundImage,
         backgroundImageCustom,
         darkTitleText,
         lenderCreditPercentage,
         realtorCommissionPercentage,
         realtorCreditPercentage,
         contactFormTitle
      } = module;
      if (!display) return null;
      switch (type) {
         case 'twitter':
            return renderTwitter(twitter);
         case 'facebookpage':
            const { facebookPageUrl } = module;
            return renderFacebookPage(facebookPageUrl);
         case 'instagram':
            const { instagramToken } = module;
            return renderInstagram(instagramToken);
         case 'video':
            const { videos = [] } = module;
            return renderVideo(videos, backgroundImageCustom, darkTitleText);
         case 'map':
            return renderMap(branch, isPreview);
         case 'calculator':
            const {
               showCalculatorMortgage,
               showCalculatorRentVsBuy,
               showCalculatorHomeAffordability,
               showCalculatorRequiredIncome,
               showCalculatorRefinance
            } = module;
            return renderCalc(
               showCalculatorMortgage,
               showCalculatorRentVsBuy,
               showCalculatorHomeAffordability,
               showCalculatorRequiredIncome,
               showCalculatorRefinance
            );
         case 'calendar':
            const { calendar } = module;
            return renderCalendar(calendar, backgroundImage, darkTitleText);
         case 'bookings':
            const { bookings } = module;
            return renderBookings(name, bookings, backgroundImage, darkTitleText);
         case 'blog':
            return renderBlog(blogs, corporateBlogs, blogWord, slug);
         case 'events':
            const { event1 = null, event2 = null, event3 = null } = module;
            return renderEvents(event1, event2, event3);
         case 'resources':
            const { resourceIntro, resourceList } = module;
            return renderResources(resourceIntro, resourceList);
         case 'awards':
            const { awards } = module;
            return renderAwards(awards);
         case 'testimonials':
            return renderTestimonials(review, isReviewInit, hasReviewErrored);
         case 'homebot':
            const { id } = module;
            return renderHomebot(id);
         case 'teamMembers':
            return renderTeamMembers(
               loanOfficers,
               managers,
               staff,
               teamMembersAllowLOLink,
               backgroundImage,
               backgroundImageCustom
            );
         case 'profile':
            const {
               profileShowCompactView,
               profileTitle,
               profileBody,
               useBackgroundImage,
               overrideAddress,
               overrideAddressText,
               licensedInText
            } = module;
            return renderProfile(
               templateKey,
               branch,
               profileShowCompactView,
               profileTitle,
               profileBody,
               useBackgroundImage,
               backgroundImage,
               backgroundImageCustom,
               darkTitleText,
               searchVisibility,
               overrideAddress,
               overrideAddressText,
               licensedInText,
               licenseLink
            );
         case 'content':
            const { contentImage, contentTitle, contentText } = module;
            return renderContent(contentImage, contentTitle, contentText);
         case 'contactUs':
            const {
               contactUsText,
               contactUsTitle,
               contactUsBammUrl,
               contactUsConfirmEmailFrom,
               contactUsConfirmEmailText,
               contactUsLeadEmailCcList
            } = module;
            return renderContactUs(
               firestoreId,
               isPreview,
               moduleIndex,
               contactFormFields,
               contactFormTitle,
               darkTitleText,
               contactUsText,
               backgroundImage,
               backgroundImageCustom,
               contactUsTitle,
               contactUsBammUrl,
               contactUsConfirmEmailFrom,
               contactUsConfirmEmailText,
               contactUsLeadEmailCcList,
               branch
            );
         case 'g2hCalculator':
            const {
               g2hCalculatorFormFields,
               g2hCalculatorFormTitle,
               g2hCalculatorText,
               g2hCalculatorTitle,
               g2hCalculatorBammUrl
            } = module;
            return renderG2HCalculator(
               firestoreId,
               isPreview,
               moduleIndex,
               g2hCalculatorFormFields,
               g2hCalculatorFormTitle,
               darkTitleText,
               g2hCalculatorText,
               backgroundImage,
               backgroundImageCustom,
               g2hCalculatorTitle,
               g2hCalculatorBammUrl,
               lenderCreditPercentage,
               realtorCommissionPercentage,
               realtorCreditPercentage,
               branch,
               leadEmailInfo
            );
         case 'aboutG2H':
            const { aboutG2HHtml, aboutG2HCobrandUrl, aboutG2HIndustryIcons } = module;
            return renderAboutG2H(aboutG2HHtml, aboutG2HCobrandUrl, aboutG2HIndustryIcons);
         case 'aboutCare':
            const {
               contactFormSubtitle,
               aboutCareHtml,
               aboutCareImage,
               allowDiscountedRealtorFee,
               noAppraisalFeeCredit,
               bammUrl
            } = module;
            return renderAboutCare(
               firestoreId,
               isPreview,
               moduleIndex,
               aboutCareHtml,
               aboutCareImage,
               backgroundImage,
               backgroundImageCustom,
               allowDiscountedRealtorFee,
               noAppraisalFeeCredit,
               bammUrl,
               contactFormFields,
               contactFormTitle,
               contactFormSubtitle,
               darkTitleText,
               lenderCreditPercentage,
               realtorCreditPercentage,
               realtorCommissionPercentage,
               pageType
            );
         case 'aboutBE':
            const { aboutBEHtml, aboutBEImage } = module;
            return renderAboutBE(aboutBEHtml, aboutBEImage, backgroundImage, backgroundImageCustom, darkTitleText);
         case 'customTestimonials':
            const { customTestimonials } = module;
            return renderCustomTestimonials(customTestimonials, backgroundImage, backgroundImageCustom);
         case 'careTestimonials':
            const { careTestimonials } = module;
            return renderCareTestimonials(careTestimonials);
         case 'becomePartner':
            return renderBecomePartner(backgroundImage, backgroundImageCustom);
         default:
            return null;
      }
   };

   return (
      <>
         {modules.map((module, moduleIndex) => {
            return renderModule(firestoreId, module, moduleIndex);
         })}
      </>
   );
};

export default ModuleList;
