import React from 'react';
import { img } from '../../lib/brand';
import styles from './styles.module.less';

var instagramIcon = img('Instagram.svg', 'SocialIcons');
var facebookIcon = img('Facebook.svg', 'SocialIcons');
var youtubeIcon = img('youtube.svg', 'SocialIcons');
var linkedinIcon = img('Linkedin.svg', 'SocialIcons');
var xIcon = img('xIcon.svg', 'SocialIcons');

const SocialIcons = ({ facebook, twitter, linkedin, youtube, instagram, darkText = true }) => {
   let className = darkText ? styles.socialIcons : `${styles.socialIcons} ${styles.socialIconsLight}`;

   return (
      <div className={className}>
         {facebook && (
            <a href={facebook} target="_blank" rel="noopener noreferrer">
               <img src={facebookIcon} alt="Follow me on Facebook" />
            </a>
         )}
         {twitter && (
            <a href={twitter} target="_blank" rel="noopener noreferrer">
               <img src={xIcon} alt="Follow me on X" />
            </a>
         )}
         {linkedin && (
            <a href={linkedin} target="_blank" rel="noopener noreferrer">
               <img src={linkedinIcon} alt="Follow me on LinkedIn" />
            </a>
         )}
         {youtube && (
            <a href={youtube} target="_blank" rel="noopener noreferrer">
               <img src={youtubeIcon} alt="Subscribe on Youtube" />
            </a>
         )}
         {instagram && (
            <a href={instagram} target="_blank" rel="noopener noreferrer">
               <img src={instagramIcon} alt="Follow me on Instagram" />
            </a>
         )}
      </div>
   );
};

export default SocialIcons;
