import React from 'react';
import { getUuidFromUrl, resizeTo } from '../../../../lib/ucImage';

/**
 * Optionally display a team graphic, fall back to styled team name
 */
const Wordmark = ({ graphic, name }) => {
   if (graphic) {
      let src = resizeTo(getUuidFromUrl(graphic), 600, 100);
      return <h1>{graphic && <img data-blink-src={src} alt={name} />}</h1>;
   }
   return <h1>{name}</h1>;
};

export default Wordmark;
