import React, { useState } from 'react';
import classnames from 'classnames';
import TestimonialsCarousel from '../TestimonialsCarousel';

import styles from './styles.module.less';
import heroes from '../shared/heroes-common.module.less';

const defaultPhoto = `whiteWood`;

const CustomTestimonials = ({ items = [], backgroundImage, backgroundImageCustom }) => {
   let heroBackground = !!backgroundImage ? backgroundImage : defaultPhoto;
   let cls = classnames(styles.widget, {
      [heroes[heroBackground]]: !backgroundImageCustom
   });
   let style = {};
   if (!!backgroundImageCustom) {
      style.backgroundImage = `url("${backgroundImageCustom}")`;
   }

   if (!items || !items.length) {
      return null;
   }

   return (
      <div className={cls} style={style}>
         <section className={styles.TestimonialSlider} data-background="photo">
            <div className={styles.inner}>
               <TestimonialsCarousel items={items} />
            </div>
         </section>
      </div>
   );
   // }
};

export default CustomTestimonials;
