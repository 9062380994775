import React from 'react';
import { imgBrand, img, isBe, mapPopupQuery } from '../../../lib/brand';
import styles from './styles.module.less';

var BEbubble = img('BEEmptyBubble.png', 'Map');
var Xbutton = img('xbutton.png', 'Map');

var beBug = imgBrand(isBe ? 'be-circle-mark.png' : 'rf-circle-mark.png');

interface PopupProps {
   title: string;
   address: string[];
   handleClick: () => void;
}

const Popup = (props: PopupProps) => {
   const { title, address, handleClick } = props;
   return (
      <div className={styles.container}>
         <div className={styles.relative}>
            <img src={BEbubble} alt={title} className={styles.bubble} />
            <img onClick={handleClick} src={Xbutton} alt="xbutton" className={styles.xbutton} />
            <img src={beBug} alt="bebug" className={styles.bebug} width="50" height="50" />
            <div className={styles.address}>
               <a
                  href={`https://www.google.com/maps?q=${mapPopupQuery(address)}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  <div className={styles.titleName}>{title}</div>
                  <div className={styles.addressParagraph}>
                     {address[0]}
                     <br />
                     {address[1] && (
                        <span>
                           {address[1]}
                           <br />
                        </span>
                     )}
                     {address[2] && (
                        <span>
                           {address[2]}
                           <br />
                        </span>
                     )}
                     {address[3]}, &nbsp;
                     {address[4]} &nbsp;
                     {address[5]}
                  </div>
               </a>
            </div>
         </div>
      </div>
   );
};

export default Popup;
