import React from 'react';
import { noop } from 'lodash';
import { img } from '../../lib/brand';

var backArrow = img('arrowLeft.svg', 'NavArrow');
var forthArrow = img('arrowRight.svg', 'NavArrow');

import styles from './styles.module.less';

export const Prev = ({ alt = 'Back', onClick = noop, style = {} }) => {
   return (
      <button className={styles.NavArrow} type="button" onClick={onClick} style={style}>
         <img src={backArrow} alt={alt} />
      </button>
   );
};

export const Next = ({ alt = 'Forward', onClick = noop, style = {} }) => {
   return (
      <button className={styles.NavArrow} type="button" onClick={onClick} style={style}>
         <img src={forthArrow} alt={alt} />
      </button>
   );
};
