import React, { ChangeEventHandler, FocusEventHandler, FormEventHandler, useState } from 'react';
import Accent from '../Accent';
import { get } from 'lodash';
import SimpleReactValidator from 'simple-react-validator';

import styles from './styles.module.less';
import states from '../../lib/states.json';

import { withStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import StaticText from '../StaticText';

interface FormField {
   name: string;
   bammName: string;
   customFieldType: string;
   label: string;
   options: CustomInputOption[];
   required: boolean;
   type: string;
}

interface CustomInputOption {
   hasCustomLabel: boolean;
   label: string;
   value: string;
}

interface PredefinedInputs {
   fields: PredefinedInputFields;
}

interface PredefinedInputFields {
   [key: string]: PredefinedInputDetails;
}

interface PredefinedInputDetails {
   label: string;
   inputs: PredefinedInput[];
}

interface PredefinedInput {
   bammName: string;
   hasBammField: boolean;
   inputType: string;
   label: string;
   name: string;
   options: PredefinedInputOption[];
}

interface PredefinedInputOption {
   bammValue: string;
   label: string;
   value: string;
}

export interface StaticTextItem {
   id: string;
   html: string;
   page: string;
   title: string;
   adminOrderBy: number;
}

interface Branch {
   email: string;
   lenderCreditPercentage: number;
   loanOfficerName: string;
   name: string;
   realtorCommissionPercentage: number;
   realtorCreditPercentage: number;
   realtorName: string;
   realtorTitle: string;
   slug: string;
}

interface FormValuesArray {
   [index: string]: string;
}

interface CustomFormProps {
   moduleIndex: number;
   formTitle: string;
   formValues: FormValuesArray;
   fields: FormField[];
   hiddenFields: string[];
   isPreview: boolean;
   predefinedInputs: PredefinedInputs;
   validator: React.RefObject<SimpleReactValidator>;
   handleBlur: ChangeEventHandler;
   handleChange: ChangeEventHandler;
   handleFormSubmit: FormEventHandler;
   showBranchesInput: boolean;
   branches?: Branch[];
   submitButtonText: string;
   staticTextItems: StaticTextItem[];
   lightText: boolean;
}
const CustomForm = (props: CustomFormProps) => {
   const {
      moduleIndex,
      formTitle,
      formValues,
      fields,
      hiddenFields = [],
      isPreview,
      predefinedInputs,
      validator,
      handleBlur,
      handleChange,
      handleFormSubmit,
      showBranchesInput = false,
      branches = [],
      submitButtonText = 'Submit',
      staticTextItems = [],
      lightText = false
   } = props;

   const [electronicRecordsConsentDialogOpen, setElectronicRecordsConsentDialogOpen] = useState(false);

   const hideElectronicRecordsConsentDialog = () => {
      setElectronicRecordsConsentDialogOpen(false);
   };

   const showElectronicRecordsConsentDialog = (e: React.MouseEvent<HTMLElement>) => {
      setElectronicRecordsConsentDialogOpen(true);
   };

   const getInputId = (base: string) => {
      return base + '-widget' + moduleIndex;
   };

   const consentIsValid = () => {
      if (formValues['disclosure'] && !formValues['electronicRecordsConsent']) return false;

      return true;
   };

   return (
      <form>
         <div className={styles.customForm}>
            {formTitle && (
               <>
                  <h1 className={styles.calch1}>{formTitle}</h1>
                  <Accent align="left" />
               </>
            )}
            <div className={styles.honeypot}>
               <div className={styles.formGroup}>
                  <input
                     type="text"
                     name="botField"
                     value={formValues['botField']}
                     aria-label="Honeypot for Netlify"
                     onChange={handleChange}
                  />
               </div>
            </div>
            {fields &&
               fields.map((field, i) => {
                  const fieldType: string = get(field, 'type');

                  if (fieldType && !hiddenFields.includes(fieldType)) {
                     // setup required, phone, and email field validations
                     let validationsArray: string[] = [];

                     if (!isPreview) {
                        if (!!field.required) validationsArray.push('required');

                        if (fieldType === 'phone') validationsArray.push('customPhone');
                        else if (fieldType === 'purchasePrice') validationsArray.push('currency');
                        else if (fieldType === 'downPayment') validationsArray.push('currency');
                        else if (fieldType === 'email') validationsArray.push('customEmail');
                     }

                     let validatorMessage = '';

                     if (fieldType === 'custom') {
                        if (
                           validationsArray.length &&
                           !isPreview &&
                           field.name // TODO:  remove after fixing a build error caused when a custom field name isn't set
                        ) {
                           validatorMessage = validator.current?.message(
                              field.name,
                              formValues[field.name],
                              validationsArray.join('|')
                           );
                        }

                        return (
                           <Field
                              key={i}
                              handleChange={handleChange}
                              index={i}
                              inputType={field.customFieldType}
                              id={getInputId(field.name)}
                              name={field.name}
                              value={formValues[field.name]}
                              label={field.label}
                              options={field.options}
                              required={field.required}
                              validatorMessage={validatorMessage}
                              disabled={false}
                              lightText={lightText}
                           />
                        );
                     } else {
                        let fields = predefinedInputs['fields'];
                        let fieldInfo = fields[fieldType];

                        return fieldInfo.inputs.map((input: PredefinedInput, j: number) => {
                           if (validationsArray.length && !isPreview) {
                              validatorMessage = validator.current?.message(
                                 input.name,
                                 formValues[input.name],
                                 validationsArray.join('|')
                              );
                           }

                           // setup options for state select
                           if (['state', 'propertyState'].includes(input.name)) {
                              let options: PredefinedInputOption[] = [];

                              // let stateAbbr: string;
                              states.forEach(state => {
                                 // for (stateAbbr in states) {
                                 let option = {
                                    label: state.label,
                                    value: state.value,
                                    bammValue: state.label
                                 };
                                 options.push(option);
                              });

                              input.options = options;
                           }

                           return (
                              <Field
                                 key={`${i}-${j}`}
                                 handleChange={handleChange}
                                 handleBlur={handleBlur}
                                 index={i}
                                 inputType={input.inputType}
                                 id={getInputId(input.name)}
                                 name={input.name}
                                 value={formValues[input.name]}
                                 label={input.label}
                                 options={input.options}
                                 required={field.required}
                                 validatorMessage={validatorMessage}
                                 disabled={false}
                                 lightText={lightText}
                              />
                           );
                        });
                     }
                  } else {
                     return;
                  }
               })}
            {showBranchesInput && (
               <div className={styles.formGroup}>
                  <label htmlFor="branchId" className={styles.label}>
                     Nearest Bay Equity Branch *
                  </label>
                  <div className={styles.inputContainer}>
                     <select
                        name="branchId"
                        className={styles.formControl}
                        value={formValues.branchId}
                        onChange={handleChange}
                        required>
                        <option value="" key="0">
                           Select...
                        </option>
                        {branches.map((b, i) => (
                           <option value={b.slug} key={i}>
                              {b.name}
                           </option>
                        ))}
                     </select>
                     <div className={styles.validationMessage}>
                        {validator.current?.message('branchId', formValues.branchId, 'required')}
                     </div>
                  </div>
               </div>
            )}
            <div className={styles.disclosure} style={{ marginTop: '1em' }}>
               <input
                  type="checkbox"
                  id={getInputId('electronicRecordsConsent')}
                  name="electronicRecordsConsent"
                  value="electronicRecordsConsent"
                  checked={!!formValues.electronicRecordsConsent}
                  onChange={handleChange}
               />
               <label htmlFor={getInputId('electronicRecordsConsent')} style={lightText ? { color: '#ffffff' } : {}}>
                  <StaticText id="widget-form-electronic-records-consent" items={staticTextItems} />{' '}
                  <button
                     type="button"
                     className={styles.linkButton}
                     style={lightText ? { color: '#ffffff' } : {}}
                     onClick={showElectronicRecordsConsentDialog}>
                     Click Here
                  </button>{' '}
                  for more information.
               </label>
            </div>
            <div className={styles.disclosure} style={{ marginTop: '1em' }}>
               <input
                  type="checkbox"
                  id={getInputId('disclosure')}
                  name="disclosure"
                  value="disclosure"
                  checked={!!formValues.disclosure}
                  onChange={handleChange}
               />
               <label htmlFor={getInputId('disclosure')} style={lightText ? { color: '#ffffff' } : {}}>
                  <StaticText
                     id="widget-form-consent"
                     items={staticTextItems}
                     className={lightText ? styles.whiteText : ''}
                  />
               </label>
               {!!formValues.disclosure && !!!formValues.electronicRecordsConsent && (
                  <div className={styles.warningOverlay}>
                     <div className={styles.warningText}>
                        Please note, you must consent to Electronic Records in order to receive Text Message updates.
                     </div>
                  </div>
               )}
            </div>
            <Dialog
               open={electronicRecordsConsentDialogOpen}
               onClose={hideElectronicRecordsConsentDialog}
               scroll="paper"
               PaperProps={{ style: { overflowY: 'unset' } }}>
               <DialogContent className={styles.disclosureDialog} classes={{ root: styles.disclosureDialog }}>
                  <StaticText id="widget-form-electronic-records-consent-dialog" items={staticTextItems} />
               </DialogContent>
               <DialogActions>
                  <Button onClick={hideElectronicRecordsConsentDialog}>OK</Button>
               </DialogActions>
            </Dialog>
            <div style={{ marginTop: '1em' }}>
               <input
                  type="button"
                  className={styles.btn}
                  onClick={handleFormSubmit}
                  disabled={!validator.current?.allValid() || !consentIsValid()}
                  value={submitButtonText}
               />
            </div>
         </div>
      </form>
   );
};

interface FieldProps {
   id: string;
   index: number;
   handleChange: ChangeEventHandler;
   handleBlur?: FocusEventHandler;
   inputType: string;
   name: string;
   value: string;
   label: string;
   options: PredefinedInputOption[] | CustomInputOption[];
   required: boolean;
   validatorMessage: string;
   disabled: boolean;
   lightText: boolean;
}
const Field = (props: FieldProps) => {
   const {
      id,
      handleChange,
      handleBlur,
      inputType,
      name,
      value,
      label,
      options,
      required,
      validatorMessage,
      disabled,
      lightText
   } = props;
   return (
      <div className={styles.row}>
         <div className={styles.col12}>
            {inputType === 'text' && (
               <TextInputRow
                  id={id}
                  name={name}
                  label={label}
                  value={value}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required={required}
                  validatorMessage={validatorMessage}
                  disabled={disabled}
                  lightText={lightText}
               />
            )}
            {inputType === 'textarea' && (
               <TextAreaRow
                  id={id}
                  name={name}
                  label={label}
                  value={value}
                  onChange={handleChange}
                  required={required}
                  validatorMessage={validatorMessage}
                  disabled={disabled}
                  lightText={lightText}
               />
            )}
            {inputType === 'select' && (
               <SelectRow
                  id={id}
                  name={name}
                  label={label}
                  value={value}
                  onChange={handleChange}
                  options={options}
                  required={required}
                  validatorMessage={validatorMessage}
                  disabled={disabled}
                  lightText={lightText}
               />
            )}
            {inputType === 'checkbox' && (
               <CheckboxRow
                  id={id}
                  name={name}
                  label={label}
                  value={value}
                  onChange={handleChange}
                  required={required}
                  validatorMessage={validatorMessage}
                  disabled={disabled}
                  lightText={lightText}
               />
            )}
            {inputType === 'checkboxList' && (
               <CheckboxListRow
                  id={id}
                  name={name}
                  label={label}
                  value={value}
                  onChange={handleChange}
                  options={options}
                  required={required}
                  validatorMessage={validatorMessage}
                  disabled={disabled}
                  lightText={lightText}
               />
            )}
            {inputType === 'radio' && (
               <RadioRow
                  id={id}
                  name={name}
                  label={label}
                  value={value}
                  onChange={handleChange}
                  options={options}
                  required={required}
                  validatorMessage={validatorMessage}
                  disabled={disabled}
                  lightText={lightText}
               />
            )}
         </div>
      </div>
   );
};

interface TextInputRowProps {
   id: string;
   name: string;
   label: string;
   value: string;
   required: boolean;
   onChange: ChangeEventHandler;
   onBlur?: FocusEventHandler;
   validatorMessage: string;
   disabled: boolean;
   lightText: boolean;
}
const TextInputRow = (props: TextInputRowProps) => {
   const { id, name, label, value = '', required, onChange, onBlur, validatorMessage, disabled, lightText } = props;

   return (
      <div className={styles.formGroup}>
         <label htmlFor={id} style={lightText ? { color: '#ffffff' } : {}}>
            {label}
            {!!required && ' *'}
         </label>
         <input
            type="text"
            name={name}
            id={id}
            value={value}
            required={!!required}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}></input>
         <div className={styles.validationMessage}>{validatorMessage}</div>
      </div>
   );
};

interface TextAreaRowProps {
   id: string;
   name: string;
   label: string;
   value: string;
   required: boolean;
   onChange: ChangeEventHandler;
   validatorMessage: string;
   disabled: boolean;
   lightText: boolean;
}
const TextAreaRow = (props: TextAreaRowProps) => {
   const { id, name, label, value = '', required, onChange, validatorMessage, disabled, lightText } = props;

   return (
      <div className={styles.formGroup}>
         <label htmlFor={id} style={lightText ? { color: '#ffffff' } : {}}>
            {label}
            {!!required && ' *'}
         </label>
         <textarea
            name={name}
            id={id}
            value={value}
            required={!!required}
            rows={4}
            onChange={onChange}
            disabled={disabled}></textarea>
         <div className={styles.validationMessage}>{validatorMessage}</div>
      </div>
   );
};

interface SelectRowProps {
   id: string;
   name: string;
   label: string;
   value: string;
   options: PredefinedInputOption[] | CustomInputOption[];
   required: boolean;
   onChange: ChangeEventHandler;
   validatorMessage: string;
   disabled: boolean;
   lightText: boolean;
}
const SelectRow = (props: SelectRowProps) => {
   const { id, name, label, value = '', options, required, onChange, validatorMessage, disabled, lightText } = props;

   return (
      <div className={styles.formGroup}>
         <label htmlFor={id} style={lightText ? { color: '#ffffff' } : {}}>
            {label}
            {!!required && ' *'}
         </label>
         <select name={name} id={id} value={value} required={!!required} onChange={onChange} disabled={disabled}>
            <option value="">-- Select One --</option>
            {options &&
               options.map((opt, i) => (
                  <option key={i} value={opt.value} defaultValue="">
                     {opt.label}
                  </option>
               ))}
         </select>
         <div className={styles.validationMessage}>{validatorMessage}</div>
      </div>
   );
};

interface CheckboxRowProps {
   id: string;
   name: string;
   label: string;
   value: string;
   required: boolean;
   onChange: ChangeEventHandler;
   validatorMessage: string;
   disabled: boolean;
   lightText: boolean;
}
const CheckboxRow = (props: CheckboxRowProps) => {
   const { id, name, label, value, required, onChange, validatorMessage, disabled, lightText } = props;

   return (
      <div className={styles.formGroup} style={{ overflowY: 'auto' }}>
         <div className={styles.checkboxRow}>
            <input
               type="checkbox"
               name={name}
               id={id}
               value={value}
               required={!!required}
               onChange={onChange}
               checked={!!value}
               disabled={disabled}></input>
            <label htmlFor={id} style={lightText ? { color: '#ffffff' } : {}}>
               {label}
               {!!required && ' *'}
            </label>
         </div>
         <div className={styles.validationMessage}>{validatorMessage}</div>
      </div>
   );
};

interface CheckboxListRowProps {
   id: string;
   name: string;
   label: string;
   value: string;
   options: PredefinedInputOption[] | CustomInputOption[];
   required: boolean;
   onChange: ChangeEventHandler;
   validatorMessage: string;
   disabled: boolean;
   lightText: boolean;
}
const CheckboxListRow = (props: CheckboxListRowProps) => {
   const { id, name, label, value, options, required, onChange, validatorMessage, disabled, lightText } = props;

   return (
      <div className={styles.formGroup} style={{ overflowY: 'auto' }}>
         <label style={lightText ? { color: '#ffffff' } : {}}>
            {label}
            {!!required && ' *'}
         </label>
         {options &&
            options.map((opt: PredefinedInputOption | CustomInputOption, i) => (
               <div className={styles.checkboxRow} key={i}>
                  <input
                     type="checkbox"
                     id={id + '.' + i}
                     name={name + '.' + opt.value}
                     value={opt.value}
                     checked={value === opt.value || undefined}
                     style={{
                        marginLeft: '1em'
                     }}
                     disabled={disabled}
                     onChange={onChange}></input>
                  <label htmlFor={id + '.' + i} style={lightText ? { color: '#ffffff' } : {}}>
                     {opt.label}
                  </label>
               </div>
            ))}
         <div className={styles.validationMessage}>{validatorMessage}</div>
      </div>
   );
};

interface RadioRowProps {
   id: string;
   name: string;
   label: string;
   value: string;
   options: PredefinedInputOption[] | CustomInputOption[];
   required: boolean;
   onChange: ChangeEventHandler;
   validatorMessage: string;
   disabled: boolean;
   lightText: boolean;
}
const RadioRow = (props: RadioRowProps) => {
   const { id, name, label, value, options, required, onChange, validatorMessage, disabled, lightText } = props;

   return (
      <div className={styles.formGroup} style={{ overflowY: 'auto' }}>
         <label style={lightText ? { color: '#ffffff' } : {}}>
            {label}
            {!!required && ' *'}
         </label>
         {options &&
            options.map((opt, i) => (
               <div className={styles.radioRow} key={i}>
                  <input
                     type="radio"
                     id={id + '.' + i}
                     name={name}
                     value={opt.value}
                     checked={value === opt.value}
                     style={{
                        marginLeft: '1em'
                     }}
                     disabled={disabled}
                     onChange={onChange}></input>
                  <label htmlFor={id + '.' + i} style={lightText ? { color: '#ffffff' } : {}}>
                     {opt.label}
                  </label>
               </div>
            ))}
         <div className={styles.validationMessage}>{validatorMessage}</div>
      </div>
   );
};

export default withStyles(styles)(CustomForm);
